import React, { useState, useEffect , useRef } from 'react';
import {
    updateClientAddress,
    createClientAddress,
    validateClientAddress,
} from '../actions/ClientAddress';
import {uploadFacturaDrop} from '../actions/Product';
import SelectStat from '../components/common/SelectStat';

export default function Component(props) {
    const [invalid, setInvalid] = useState([]);
    const [clientAddress, setClientAddress] = useState({
        client_name: '',
        ulice: '',
        stat: '',
        mesto: '',
        psc: '',
        telefon: '',
        email: '',
        cash_on_delivery: '',
        factura_drop: ''
    });

    const isPageRefreshed = useRef(false);

    useEffect(() => {
        let validation = validateClientAddress(
            clientAddress,
            props.isDropShipping
        );
        if (validation === true) {
            setInvalid([]);
            if (props.onValidated !== false) {
                props.onValidated(clientAddress);
            }
        } else if (
            props.onValidated !== false &&
            Object.keys(clientAddress).length > 0
        ) {
            setInvalid(validation);
            if (props.onValidated !== false) {
                // props.onValidated({ clientAddress: '' });
            }
        }
    }, [clientAddress]);

    useEffect(() => {
        if ( isPageRefreshed.current ) return;
        setInvalid([]);
        if (props.clientAddress) {
            setClientAddress(props.clientAddress);
            isPageRefreshed.current = true;
        }
    }, [props]);

    const submit = async () => {
        let validation = validateClientAddress(
            clientAddress,
            props.isDropShipping
        );
        if (true !== validation) {
            setInvalid(validation);
        } else {
            setInvalid([]);
            console.log(clientAddress);
            if (props.action == 'edit') {
                await updateClientAddress(clientAddress);
            } else {
                await createClientAddress(clientAddress);
            }
            props.onSubmit();
        }
    };
    const handleChange = (e, key) => {
        if (key === 'stat') {
            e = {
                target: {
                    value: e,
                },
            };
        }
        if (undefined == e.target) {
            setClientAddress({ ...clientAddress, [key]: +e });
        } else {
            setClientAddress({ ...clientAddress, [key]: e.target.value });
        }
    };
    
    const handleFileChange = async (e) => {

        
        console.log('Uploading file...');
        if (e.target.files[0]) {
            
            let file1 = e.target.files[0];
            const formData = new FormData();
            formData.append('file', file1);

            try {
              
              let resp = await uploadFacturaDrop(formData);

              setClientAddress({ ...clientAddress, 'factura_drop': resp });
              
              console.log(clientAddress);
            } catch (error) {
              console.error(error);
            }
          }
        console.log(e.target.files[0]);  
    };

    return (
        <div className="order-address">
            <div className="order-address-cols">
                <div className="order-address-left">
                    <div className="input-block">
                        <input
                            autoFocus={true}
                            placeholder="Имена"
                            value={clientAddress['client_name']}
                            onChange={(e) => handleChange(e, 'client_name')}
                            className={`input-block__field ${
                                invalid.includes('client_name') && 'is-invalid'
                            }`}
                            required=""
                        />
                    </div>
                    <div className="input-block">
                        <input
                            placeholder="Адрес"
                            value={clientAddress['ulice']}
                            onChange={(e) => handleChange(e, 'ulice')}
                            className={`input-block__field ${
                                invalid.includes('ulice') && 'is-invalid'
                            }`}
                            required=""
                        />
                    </div>
                    <div className="input-block">
                        <SelectStat
                            value={clientAddress['stat']}
                            onChange={(e) => handleChange(e, 'stat')}
                            className={
                                invalid.includes('stat') ? 'is-invalid' : ''
                            }
                        />
                    </div>

                </div>
                <div className="order-address-right">
                    <div className="order-address-right__row">
                        <div className="input-block">
                            <input
                                placeholder="Населено място"
                                value={clientAddress['mesto']}
                                onChange={(e) => handleChange(e, 'mesto')}
                                className={`input-block__field ${
                                    invalid.includes('mesto') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                        <div className="input-block">
                            <input
                                placeholder="Пощенски код"
                                value={clientAddress['psc']}
                                onChange={(e) => handleChange(e, 'psc')}
                                className={`input-block__field ${
                                    invalid.includes('psc') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                    </div>
                    <div className="order-address-right__row">
                        <div className="input-block">
                            <input
                                placeholder="Телефон"
                                value={clientAddress['telefon']}
                                onChange={(e) => handleChange(e, 'telefon')}
                                className={`input-block__field ${
                                    invalid.includes('telefon') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                        <div className="input-block">
                            <input
                                placeholder="E-mail"
                                value={clientAddress['email']}
                                onChange={(e) => handleChange(e, 'email')}
                                className={`input-block__field ${
                                    invalid.includes('email') && 'is-invalid'
                                }`}
                                required=""
                            />
                        </div>
                        {
                            props.isDropShipping === true ? (
                                <div className="input-block">
                                    <input
                                        placeholder={_e('Наложен платеж')}
                                        value={
                                            clientAddress['cash_on_delivery']
                                        }
                                        onChange={(e) =>
                                            handleChange(e, 'cash_on_delivery')
                                        }
                                        className={`input-block__field ${
                                            invalid.includes(
                                                'cash_on_delivery'
                                            ) && 'is-invalid'
                                        }`}
                                        required=""
                                    />
                                </div>
                        ) : null
                        }
                        </div>
                        <div className="order-address-right__row">
                        {
                            props.isDropShipping === true ? (        
                                <div className="input-block">
                                    <div className="fileUpload"><label>Файл с фактури</label>
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="input-block__field"
                                    /></div>
                                </div>
                            ) : null
                            // <div className="order-address-button__wrap">
                            //     <button
                            //         className="button button--solid order-address-button"
                            //         onClick={submit}
                            //     >
                            //         {_e('Save address')}
                            //     </button>
                            // </div>
                        }
                    </div>
                </div>
            </div>
            {!props.isDropShipping && (
                <div className="order-address-button__wrap">
                    <button
                        className="button button--solid order-address-button"
                        onClick={submit}
                        type="button"
                    >
                        {_e('Save address')}
                    </button>
                </div>
            )}
        </div>
    );
}
