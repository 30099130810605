import React, { useState, useEffect } from 'react';
import logo from '../../logo.jpg';
import staticActions from '../../redux/actions/Static';
import authActions from '../../redux/actions/Auth';
import modalActions from '../../redux/actions/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useLocation } from 'react-router-dom';

function Screen(props) {
    let location = useLocation();
    useEffect(() => {
        actions.getStatic();
    }, []);
    const [dropdown, setDropdown] = useState(false);
    const [orderProductCount, setOrderProductCount] = useState(0);
    const toggleDropDown = () => {
        setDropdown(!dropdown);
    };
    const { actions, feed, orderProducts, user } = props;
    useEffect(() => {
        const productCount = Object.values(orderProducts).reduce(
            (sum, product) => sum + product.count,
            0
        );
        setOrderProductCount(productCount);
    }, [orderProducts]);
    return (
        <header className="header">
            <div className="container">
                <div className="btn-hamburger-wrap js-menu_toggle closed">
                    <div className="btn-hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="logo">
                    <Link to="/">
                        <picture>
                            <source
                                media="(min-width:1366px)"
                                srcSet="./assets/img/header/logo.svg"
                                type="image/svg+xml"
                            />
                            <source
                                media="(max-width:1365px)"
                                srcSet="./assets/img/header/logo-mobile.svg"
                                type="image/svg+xml"
                            />
                            <img src="./assets/img/tochka.png" alt="logo" />
                        </picture>
                    </Link>
                </div>
                <a
                    onClick={actions.toggleTermsModal}
                    className="button button--o header-link-conditions pointer"
                >
                    {global._e('Obchodní podmínky')}
                </a>
                <nav className="navigation">
                    <ul className="navigation__list">
                        <li className="navigation__item">
                            <a
                                href={
                                    props.feed ??
                                    'https://dealer.dashandel.cz/export-beta.xml'
                                }
                                download
                                className="navigation__link"
                            >
                                {global._e('Feed')}
                            </a>
                        </li>
                        <li className="navigation__item">
                            <a
                                href={
                                    props.export ??
                                    'https://dealer.dashandel.cz/export.xlsx'
                                }
                                download
                                className={`navigation__link ${
                                    location.pathname === 'somebaba'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                {global._e('Tabulka produktů')}
                            </a>
                        </li>
                        <li className="navigation__item">
                            <Link
                                to="/"
                                className={`navigation__link ${
                                    location.pathname === '/' ? 'active' : ''
                                }`}
                            >
                                {global._e('Hlavní stránka')}
                            </Link>
                        </li>
                        <li className="navigation__item">
                            <Link
                                to="/orders"
                                className={`navigation__link ${
                                    location.pathname === '/orders'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                {global._e('Moje objednávky')}
                            </Link>
                        </li>
                        <li className="navigation__item pointer">
                            <Link
                                to="/addresses"
                                className={`navigation__link ${
                                    location.pathname === '/addresses'
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                {user?.name}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div
                    className="contacts modal-contacts__toggle"
                    onClick={actions.toggleModal}
                >
                    <span className="contacts__icon">
                        <picture>
                            <source
                                media="(min-width:1366px)"
                                srcSet="./assets/img/icons/phone-circle.png"
                                type="image/png"
                            />
                            <source
                                media="(max-width:1365px)"
                                srcSet="./assets/img/icons/phone-circle-mobile.png"
                                type="image/png"
                            />
                            <img
                                src="/./assets/img/tochka.png"
                                alt="phone-circle"
                            />
                        </picture>
                    </span>
                </div>
                {orderProductCount > 0 ? (
                    <Link
                        to={{
                            pathname: '/step_0',
                            state: { fromDashboard: true },
                        }}
                        className="basket"
                    >
                        <span className="basket__icon">
                            <picture>
                                <source
                                    media="(min-width:1366px)"
                                    srcSet="./assets/img/icons/cart-header.png"
                                    type="image/png"
                                />
                                <source
                                    media="(max-width:1365px)"
                                    srcSet="./assets/img/icons/cart-header-mobile.png"
                                    type="image/png"
                                />
                                <img
                                    src="/./assets/img/tochka.png"
                                    alt="cart-header"
                                />
                            </picture>
                        </span>
                        <span className="basket__count">
                            {orderProductCount}
                        </span>
                    </Link>
                ) : null}
            </div>
        </header>
    );
}

const mapStateToProps = (state) => ({
    export: state.staticReducer.export,
    feed: state.staticReducer.feed,
    orderProducts: state.orderReducer.products,
    user: state.authReducer.user,
});

const ActionCreators = Object.assign(
    {},
    authActions,
    staticActions,
    modalActions
);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
