import axios from 'axios';

axios.defaults.withCredentials = true;

export const getProducts = async (data) => {
    let header = await global.getHeader();
    let res = await axios
        .post(`${global.API_ADDRESS}/api/getProducts`, data, header)
        .catch(global.handleError);
    return res.data;
};

export const createOrder = async (data) => {
    let header = await global.getHeader();
    let res = await axios
        .post(`${global.API_ADDRESS}/api/createOrder`, data, header)
        .catch(global.handleError);
    return res?.data;
};
export const uploadFacturaDrop = async (data) => {
    
    let header = await global.getHeader();
    let res = await axios
        .post(`${global.API_ADDRESS}/api/uploadFacturaDrop`, data, header)
        .catch((error) => {
                if (error.response) {
                    //console.log(error.response.data.error);
                    global.Alert(error.response.data.error);
                } else {
                    global.Alert(error.toString());
                }
                });
    global.Alert(_e("uploadFacturaDrop_success"), "info");
    return res?.data.filename;
};
